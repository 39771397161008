* {
  padding: 0;

  margin: 0;

  border: none;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}
.text-black,
a.aptos:hover {
  color: #000 !important;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.lh-0 {
  line-height: 0;
}
.fw-600 {
  font-weight: 600 !important;
}
.text-green {
  color: #2ed191;
}
.text-green-dark,
.hovgreen:hover {
  color: #2aa372;
}
.d-none-xl {
  display: none;
}
/* ========================== index ========================== */
.form-control:focus,
.input-group-append button:focus {
  box-shadow: none;
}
.sidebar .sidebar-brand .sidebar-brand-text {
  font-size: 22px;
}
.sidebar-dark .nav-item .nav-link:hover i,
.sidebar-dark .nav-item .nav-link,
.sidebar-dark .nav-item .nav-link:hover,
.sidebar-dark .nav-item .nav-link i,
.sidebar-dark .sidebar-brand {
  color: #000;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #1e2026;
}

.dropdown-item {
  background-color: transparent !important;
}

a.nav-link {
  color: #000;
  text-align: center;
}

.nav-pills .nav-link {
  border: none;
  background: transparent;
  display: flex;
  justify-content: start;
  align-items: baseline;
  padding: 13px;
  margin: 5px 0;
}
.nav-pills .nav-link:hover {
  background: #e6e8ea;
}
.sidebar .sidebar-brand {
  height: auto;
}
.sidebar-dark #sidebarToggle,
.sidebar-dark #sidebarToggle:hover {
  background: #2ed191;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2ed191;
  background: #2aa3721a;
  font-weight: 600;
}

.leftbar {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding-bottom: 16px;
}

.leftbar .nav-link span i {
  display: flex;
  width: 30px;
}
.leftbar .nav,
.dropdown-toggle {
  font-size: 14px;
}
.leftbar i {
  font-size: 16px;
}
.fs-mini {
  font-size: 8px;
}
.online::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background: #02c076;
  transform: translateY(-50%);
  top: 50%;
  left: 13px;
}
.online::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 13px;
}

.dropdown.border {
  border-radius: 8px;
}

.dark-mode .text-black,
a.aptos:hover {
  color: #fff;
}

.navbar-search,
.navbar-search input,
.navbar-search input:focus,
.bg-grey-light,
footer {
  background: #f5f5f5;
}
.input-group.border,
.navbar-search {
  border-radius: 10px;
  width: 480px;
}
.input-group.border input {
  border-radius: 9px;
}

/* .mode i:hover {
  color: #fff;
  background-color: #2aa372 !important;
} */

.round-8 {
  border-radius: 8px;
}
.coin-green {
  /* background: url("/img/bg4.png") no-repeat; */
  padding: 56px 30px;
}
.sidebar.toggled .nav-pills .nav-link {
  display: block;
  padding: 6px;
}

.sidebar.toggled .leftbar span i {
  width: auto;
  justify-content: center;
  margin-bottom: 4px;
}
.sidebar.toggled .powtext {
  display: none;
}
.sidebar.toggled .sidebar-brand-icon {
  margin-right: 0 !important;
}
.sidebar.toggled .sidebar-brand-icon img {
  width: 30px;
}
.sidebar.toggled .sidebar-brand {
  justify-content: center;
}
.sidebar.toggled .dropdown-toggle.online {
  display: block !important;
}
.sidebar.toggled .dropdown-toggle.online::before {
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
}
.sidebar.toggled .dropdown-toggle.online::after {
  top: 86%;
  right: 45px;
}
.dark-mode,
.dark-mode .navbar-expand,
.dark-mode .bg-dark-mode {
  background-color: #1e2026 !important;
  color: #fff;
}

.dark-mode .text-black {
  color: #fff !important;
}

.dark-mode .sidebar-brand,
.dark-mode .dropdown-toggle,
.dark-mode .mode-light,
.dark-mode .text-black,
.dark-mode .nav-pills .nav-link {
  color: #fff;
}
.dark-mode #content,
.dark-mode footer,
.dark-mode .bg-dark2-mode {
  background: #14151a !important;
}
.dark-mode .border-left {
  border-left: 1px solid #2e323a !important;
}
.dark-mode .border-dark-mode {
  border: 1px solid #2e323a !important;
}
.dark-mode .text-dark-mode {
  color: #000 !important;
}
.dark-mode .text-muted-dark {
  color: #76808f;
}
.dark-mode .bg-muted {
  background: #2b2f36;
}
.dark-mode .logo-light {
  display: block;
}
.dark-mode .d-none-light {
  display: none;
}
.dark-mode .border-none-mode {
  border: none !important;
}
.dark-mode .border-right {
  border-right: 1px solid #2e323a !important;
}
.dark-mode .table td,
.table th {
  border-top: 1px solid #2e323a !important;
}
.dark-mode .nav-pills .nav-link:hover {
  background: #2e323a;
}

.dark-mode .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2ed191;
}

.table td {
  height: 58px;
  vertical-align: middle;
  font-weight: 500;
  padding-right: 8px;
}

/* .sidebar-toggled .input-group.border {
    width: 410px;
} */

/* Home */
.fichain-exlorer {
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  font-family: poppins;
  margin-bottom: 12px;
}

/* End Home */
/* ========================== transactions ========================== */

/* ========================== footer ========================== */
/* footer .rightlist ul li:not(:last-child) {
  margin-bottom: 11px;
} */

footer {
  padding: 0 32px;
}
footer .fa-twitter {
  color: #00acee;
}
footer .fa-linkedin {
  color: #0a66c2;
}
footer .fa-youtube {
  color: #ff0000;
}
footer .social {
  font-size: 18px;
}
footer .social li:not(:last-child) {
  margin-right: 12px;
}
footer h6 {
  font-size: 15px;
}
.footer-end {
  height: 72px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-top: 1px solid #e6e8ea;
}

.dark-mode .footer-end {
  height: 72px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-top: 1px solid #2e323a;
}

.footer-start {
  padding: 0px 8px;
  font-size: 14px;
  line-height: 18px;
  color: #aeb4bc;
  display: flex;
  flex-direction: column;
}

.footer-content {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 40px 0px;
  flex: 1 1 0%;
}
.footer-left {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  margin-right: 24px;
}

.footer-list {
  padding: 9px -12px 0px 0px;
}
.footer-list li {
  height: 36px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.tp-widget-wrapper {
  display: inline-block;
  font-size: 13px;
  line-height: 15px;
  vertical-align: bottom;
  margin-bottom: 20px;
}

.tp-widget-rating {
  color: #43c621;
}

.tp-logo__text {
  fill: #191919;
}
.dark-mode .tp-logo__text {
  fill: #fff;
}
/* End Footer*/

.btn:hover {
  color: #858796;
  text-decoration: none;
}

.topbar .navbar-search input {
  height: auto;
}

.my-1 a,
.my-2 {
  text-decoration: none;
  color: #43c621 !important ;
}

.fa-brands {
  color: #43c621;
}

footer .fa-twitter,
footer .fa-linkedin {
  color: #43c621;
}
/* ========================== responsive ========================== */

@media (max-width: 820px) {
  .input-group.border,
  .navbar-search {
    width: auto;
  }
  .coin-green {
    padding: 14px 25px;
    background-position: bottom;
  }
  .min-70 {
    min-width: 70px;
  }
  .min-80 {
    min-width: 80px;
  }
  .min-177 {
    min-width: 177px;
  }
  .min-125 {
    min-width: 125px;
  }
  .min-90 {
    min-width: 90px;
  }
}

@media (max-width: 390px) {
  /* .main-content {
    width: auto !important;
    margin-left: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
  } */

  .topbar-css {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 14px 16px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: #fff;
    height: 45px;
    /* box-shadow: rgba(0, 0, 0, 0.09) 0px -1px 0px inset; */
    position: sticky;
    top: 0px;
    z-index: 999;
    flex-shrink: 0;
  }

  .topbar-css-logo {
    width: 124px;
    height: 32px;
    display: inline-block;
    line-height: 1em;
    flex-shrink: 0;
    vertical-align: middle;
    fill: rgb(174 180 188);
  }

  .sidebar {
    display: none !important;
  }

  .main-content {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .topbar {
    width: 100% !important;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 14px 16px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    background: rgb(30 32 38);
    height: 56px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px -1px 0px inset;
    position: sticky;
    top: 0px;
    z-index: 999;
    flex-shrink: 0;
  }

  .css-online {
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    background: rgb(2 192 118);
  }

  .css-mainNet {
    position: relative;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid rgb(230 232 234);
    background: #fff;
    border-radius: 8px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 3px;
    margin-bottom: 5px;
  }

  .css-mainNet-text {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 6px;
  }

  .container-fluid {
    padding: 16px;
  }

  .d-flex-xs {
    display: flex;
  }
  .d-none-xs {
    display: none;
  }
  .nav-xs.d-none {
    display: block !important;
  }
  .bs-canvas-overlay {
    opacity: 0;
    z-index: -1;
  }
  .bs-canvas-overlay.show {
    opacity: 0.85;
    z-index: 1100;
  }
  .bs-canvas-overlay,
  .bs-canvas {
    transition: all 0.4s ease-out;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
  }
  .bs-canvas {
    top: 0;
    z-index: 1110;
    overflow-x: hidden;
    overflow-y: auto;
    width: 312px;
  }
  .bs-canvas-left {
    left: 0;
    margin-left: -330px;
  }
  .bs-canvas-right {
    right: 0;
    margin-right: -330px;
  }
  .online::before {
    transform: translateY(-50%) !important;
    top: 50% !important;
    left: 10px !important;
  }
  .online::after {
    top: 50% !important;
    right: 10px !important;
  }
}

/*----------- Miner details */

/* .css-1bs17yj {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 20px 0px;
}
.css-1qh0lin {
  font-size: 14px;
  min-width: 240px;   
  max-width: 240px;
  width: 240px;
  color: #76808f;
  align-self: flex-start;
}

.css-z0hvlf {
  white-space: normal;
  overflow: auto;
  text-overflow: clip;
  font-size: 14px;
  width: 100%;
  color: #1e2026;
} */

.css-minerDetail-tableText {
  display: flex;
  font-size: 16px;
  color: #1e2026;
  font-weight: 500;
  height: 32px;
  line-height: 21px;
  white-space: nowrap;
  width: 100%;
  justify-content: space-between;
  /* overflow: scroll; */
}

.css-minerDetail-navText {
  margin-right: 24px;
  position: relative;
  cursor: pointer;
}

.css-minerDetail-header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin: 10px;
}

.css-minerDetail {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 14px;
}

.css-minerDetail-back {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  max-width: 72px;
  border-radius: 20px;
  padding: 8px 0px;
  font-size: 16px;
}

.css-minerDetail-backIcon {
  width: 24px;
  height: 24px;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: #1e2026;
  vertical-align: middle;
  fill: currentcolor;
}

.css-sidebarIcon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  /* color: #1e2026; */
  vertical-align: middle;
  fill: currentcolor;
}

.css-pagination {
  padding-top: 15px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.css-copyIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 5px;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: #1e2026;
  vertical-align: middle;
  fill: currentcolor;
  cursor: pointer;
}

.css-minerDetail-copy {
  display: flex;
}

.css-minerDetail-text {
  margin: 0;
  font-size: 24px;
}
/* Miner */

.css-miner-noData {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 360px;
  font-family: poppins;
}

.css-noData-form {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.css-noData-text {
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
}

.css-noDataAvai-text {
  color: #76808f;
  font-size: 14px;
}

/*  */

button:focus {
  outline: none;
}

.pagination {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.5;
  font-weight: 600;
  height: 1.5rem;
  min-width: 1.5rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  /* background: #f5f5f5; */
  color: #1e2026;
  margin-right: 0.25rem;
}

.pagination > .active > a {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  width: auto;
  line-height: 1.5;
  font-weight: 600;
  height: 1.5rem;
  min-width: 1.5rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  background: #2aa372;
  color: #ffffff;
  margin-right: 0.25rem;
  border: none;
  text-decoration: none;
}

.pagination > li > a {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.5;
  font-weight: 600;
  height: 1.5rem;
  min-width: 1.5rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  background: #e6e8ea;
  color: #2b2f36;
  margin-right: 0.25rem;
  text-decoration: none;
}

.pagination > li > a,
.pagination > li > span {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.5;
  font-weight: 600;
  height: 1.5rem;
  min-width: 1.5rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  background: #e6e8ea;
  color: #2b2f36;
  margin-right: 0.25rem;
  text-decoration: none;
}

/* top bar */
.css-fichain-api {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 21px;
  font-weight: 600;
  height: 34px;
  min-width: 112px;
  padding-inline: 12px;
  border-radius: 24px;
  font-size: 14px;
  background: #f5f5f5;
  color: #1e2026;
  flex-shrink: 0;
  margin-left: 16px;
  border: none;
}

.topbar {
  height: 68px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 14px;
  z-index: 999;
  position: fixed;
  flex-shrink: 0;
  top: 0px;
  right: 0px;
  width: calc(100% - 220px);
  background: #fff;
  border-left: 1px solid #e6e8ea;
  color: #1e2026;
}

/* Top bar dark-mode button */

.css-dark-light {
  height: 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 32px;
  padding: 4px;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.css-theme:nth-of-type(1) {
  background: #2aa372;
  margin-right: 6px;
  color: #fff;
}

.dark-mode .css-theme:nth-of-type(1) {
  background: rgb(30 32 38);
  margin-right: 6px;
}

.css-theme:nth-of-type(2) {
  background: #fff;
}

.dark-mode .css-theme:nth-of-type(2) {
  background: #2aa372;
}

.css-space {
  width: 16px;
}

/* Topbar - search input */

.css-topbar {
  width: 480px;
  height: 100%;
  position: relative;
}

.css-topbar-search {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  padding: 0px 12px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: #fff;
  border: 1px solid rgb(230 232 234);
}

.css-85jvbr {
  display: inline-block;
  line-height: 1em;
  vertical-align: middle;
  fill: currentcolor;
  flex-shrink: 0;
  width: 24px;
  min-width: 24px;
  height: 24px;
  cursor: pointer;
  color: #1e2026 !important;
}

.css-topbar-search input {
  height: 100%;
  border: none;
  flex: 1 1 0%;
  color: #1e2026;
  padding: 0px;
  margin-left: 4px;
  background: #fff;
  outline: none;
}

/* Seach result */
.css-noResult {
  max-height: 398px;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  left: 0px;
  background-color: #fff;
  border-top: 1px solid #e6e8ea;
  border-bottom: 1px solid #e6e8ea;
  border-left: 1px solid #e6e8ea;
  border-right: 1px solid #e6e8ea;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.dark-mode .css-noResult {
  max-height: 398px;
  overflow-y: auto;
  width: 100%;
  position: absolute;
  left: 0px;
  background-color: #1e2026;
  border-top: 1px solid #2e323a;
  border-bottom: 1px solid #2e323a;
  border-left: 1px solid #2e323a;
  border-right: 1px solid #2e323a;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.css-topbar-searchItems {
  padding: 8px 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  font-family: poppins;
  text-decoration: none !important;
  background: #fff;
}

.dark-mode .css-topbar-searchItems {
  padding: 8px 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  font-family: poppins;
  text-decoration: none !important;
  background: #2e323a;
}

.css-topbar-searchItems:hover {
  background: #e6e8ea;
  color: #02c076 !important;
}

.dark-mode .css-topbar-searchItems:hover {
  background: #2b2f36 !important;
}

.css-searchItem-result {
  flex: 1 1 0%;
}

.css-result {
  font-size: 14px;
  line-height: 21px;
  color: #1e2026;
}

.css-result:hover {
  font-size: 14px;
  line-height: 21px;
  color: #02c076 !important;
}

.css-1cem2xo {
  font-size: 10px;
  color: #76808f;
}

.css-search-showMore {
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
  color: #1e2026;
  border-top: 1px solid #e6e8ea;
  background: #fff;
}

.dark-mode .css-search-showMore {
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
  color: #1e2026;
  border-top: 1px solid #2e323a;
}

.css-showMore-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: currentcolor;
  vertical-align: middle;
  fill: currentcolor;
}

/* search no result */

.css-noResult-container {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.css-noResult-header {
  height: 105px;
}

.css-noResult-mid {
  height: 22px;
}

.css-noResult-img {
  font-weight: 600;
  font-size: 16px;
  word-break: break-all;
  padding: 0px 20px;
}

.css-noResult-text {
  font-size: 14px;
  margin-top: 8px;
  color: #76808f;
}

.css-noResult-end {
  height: 116px;
}

/* search result */

.css-topbar-dropdown {
  border-bottom: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.dark-mode .css-topbar-dropdown {
  border-bottom: 1px solid #2e323a;
  border-left: 1px solid #2e323a;
  border-right: 1px solid #2e323a;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
}

.css-topbar-dropdown-header {
  padding: 8px 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* color: #2aa372; */
  background: #f5f5f5;
  position: sticky;
  font-family: poppins;
  top: 0px;
  height: 34px;
}

.dark-mode .css-topbar-dropdown-header {
  padding: 8px 24px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* color: #2aa372; */
  background: #14151a;
  position: sticky;
  font-family: poppins;
  top: 0px;
  height: 34px;
}

.css-topbar-clear {
  width: 20px;
  height: 20px;
  background: #5e6673;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.css-topbar-removeIcon {
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  vertical-align: middle;
  fill: currentcolor;
  cursor: pointer;
  color: #ffffff;
  width: 20px;
  height: 20px;
}
/* 3-6-2023  sidebar*/

.dashboard-mid {
  height: 68px;
}

.container-fluid {
  padding: 24px;
}

.sidebar {
  padding: 16px 16px 0px;
  background: #fff;
  position: fixed;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 220px;
  width: 100%;
}

.dark-mode .sidebar {
  padding: 16px 16px 0px;
  background: #1e2026;
  position: fixed;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-width: 220px;
  width: 100%;
}

.main-content {
  width: calc(100% - 220px);
  margin-left: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-mid {
  flex: 1 1 0%;
}

.css-nav-sidebar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.css-sidebar-text {
  text-align: left;
  margin-left: 12px;
}

.css-sun {
  width: 20;
  height: 20;
  fill: none;
}

.css-moon {
  width: 16;
  height: 16;
  fill: #000;
}

.dark-mode .css-moon {
  width: 16;
  height: 16;
  fill: #fff;
}

.css-theme {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
}
